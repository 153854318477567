/**
 * Layout component that queries for data with Gatsby's useStaticQuery
 * component.
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, navigate, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Header from './header';
import Footer from './footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/components/layout.css';

const Layout = ({ children, className, requireLogin, loggedIn }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    if (requireLogin && !loggedIn) {
        navigate('/login');
        // Explicitly short-circuit this page's rendering to not have
        // flickering effect before the login page comes on.
        return null;
    }
    return (
        <div className="root">
            <Header siteTitle={data.site.siteMetadata.title} />
            <main className={className}>
                {children}
            </main>
            <Footer />
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default connect(
    ({ enso: { loggedIn } }) => ({ loggedIn }),
)(Layout);
