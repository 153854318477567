import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import '../styles/components/header.css';

const Header = () => {
    const { logoImage } = useStaticQuery(graphql`
        query {
            logoImage: file(
                sourceInstanceName: { eq: "images" }
                relativePath: { eq: "enso_logo_white.png" },
            ) {
                childImageSharp {
                    fixed(width: 190) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);
    return (
        <header>
            <div className="inner-container">
                <Img
                    fixed={logoImage.childImageSharp.fixed}
                    alt="Enso Data Logo"
                    style={{
                        // Prevent image from taking up extra space below it by
                        // overriding default `display: inline-block`.
                        display: 'block',
                    }}
                />
            </div>
        </header>
    );
};

export default Header;
