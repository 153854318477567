import React from 'react';
import '../styles/components/footer.css';

const Footer = () => {
    return (
        <footer>
            <p>
                Copyright &copy; {new Date().getFullYear()} Enso Data. All
                rights reserved.
            </p>
        </footer>
    );
};

export default Footer;
